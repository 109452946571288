import React from 'react';

import Home from '../components/home';
import About from '../components/about';
import Footer from '../components/footer';
import { graphql } from 'gatsby';
import CardList from '../components/cardList';
// import Contact from '../components/contact';
import './index.css';
import './flexboxgrid.min.css';

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

export default ({ data }) => {
  return (
    <div>
      <div className="page-wrapper">
        <Home />
        <About />
        <CardList props={data} />
        {/* <Contact /> */}
        <Footer />
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
