import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import blue from '@material-ui/core/colors/blue';
import Scroller from './scrollToPage';
import { StyledLink, ModularRowAbout } from './global';
import styled from 'styled-components';

const StyledWorkLink = styled(StyledLink)`
  color: ${blue[200]};
`;

const bio = {
  firm: {
    name: '8 Securities',
    homepage: '//8securities.com/',
  },
};

const About = (props) => {
  // let h1Variant;
  // let descVariant;
  return (
    <ModularRowAbout id={'about'}>
      <Grid container>
        <Grid item lg={7} xs={12}>
          <div>
            <div>
              <Typography variant="headline" gutterBottom color="secondary">
                <em>Hi, this is Kenny Leung</em>
              </Typography>

              <Typography variant="title">
                I am a UI/UX developer based in Hong Kong. I work at{' '}
                <StyledWorkLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={bio.firm.homepage}
                >
                  {bio.firm.name}
                </StyledWorkLink>{' '}
                where I craft user experience and get my hands dirty with code.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Scroller to="portfolio" />
    </ModularRowAbout>
  );
};

export default About;
