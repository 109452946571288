import React, { Component } from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { MD } from './global';
import styled from 'styled-components';

const StyledCard = styled(Card).attrs({ color: 'secondary' })`
  background: #ffffff !important;
  padding: 8px;
  margin: 8px;
  transition: all 0.1s ease-in-out;
  width: auto !important;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.03);
    transition: all 100ms ease-in-out;
  }

  .title {
    min-height: 3.5em;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

const styleImg = {
  width: '100%',
};

class PortfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elevation: 2,
    };
  }

  hoverOnCard = () => this.setState({ elevation: 24 });
  hoverOffCard = () => this.setState({ elevation: 2 });

  render() {
    return (
      <Grid item xs={6} md={3} lg={2}>
        <StyledCard
          onMouseOver={this.hoverOnCard}
          onMouseOut={this.hoverOffCard}
          elevation={this.state.elevation}
        >
          <Link to={this.props.node.fields.slug}>
            <div>
              <div className="thumbnail">
                <Img
                  style={styleImg}
                  fluid={
                    this.props.node.frontmatter.thumbnail.childImageSharp.fluid
                  }
                  alt={this.props.node.frontmatter.title}
                />
              </div>
              <MD>
                <div className="title">
                  <Typography
                    align="center"
                    color="primary"
                    style={{ margin: '4px 0' }}
                    variant="caption"
                  >
                    {this.props.node.frontmatter.title}
                  </Typography>
                </div>
              </MD>
            </div>
          </Link>
        </StyledCard>
      </Grid>
    );
  }
}

export default PortfoCard;
