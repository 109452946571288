import React from 'react';
import PortfoCard from './card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ModularRowPortfo } from './global';

export default ({ props }) => {
  return (
    <ModularRowPortfo id={'portfolio'}>
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <Typography variant="headline" gutterBottom>
            Portfolio
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        {props.allMarkdownRemark.edges.map((card) => (
          <PortfoCard key={card.node.fields.slug} {...card} />
        ))}
      </Grid>
    </ModularRowPortfo>
  );
};
