import React from 'react';
import Icon from './icon';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledScroller = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  padding: 16px 0;
  display: block;
`;

const Scroller = (props) => {
  return (
    <StyledScroller>
      <StyledLink to={'#' + props.to}>
        <Icon name="down-open" size="1.3" />
      </StyledLink>
    </StyledScroller>
  );
};

Scroller.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Scroller;
